import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutes } from './app.routes';

import { FormsModule } from '@angular/forms';

import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { VoteComponent } from './vote/vote.component';

import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';

import { AuthService } from './core/auth.service';
import { AuthGuard } from './core/auth.guard';
import { DataService } from './services/data.service';
import { QuestionFilterPipe } from './shared/question-filter.pipe';
import { OptionFilterPipe } from './shared/option-filter.pipe';
import { VoteFilterPipe } from './shared/vote-filter.pipe';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AdminComponent } from './admin/admin.component';
import { ResultComponent } from './result/result.component';


@NgModule({
  declarations: [
    AppComponent,
    CreateComponent,
    VoteComponent,
    ResultComponent,
    QuestionFilterPipe,
    OptionFilterPipe,
    VoteFilterPipe,
    UserProfileComponent,
    AdminComponent 
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutes,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase, 'angular-auth-firebase'),
    CoreModule
  ],
  providers: [AuthService, AuthGuard, DataService],
  bootstrap: [AppComponent]
})

export class AppModule { }