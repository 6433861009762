import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { Option } from '../shared/option';
import { Question } from '../shared/question';
import { Form } from '../shared/form';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit{

  formId: string;
  questions: Observable<Question[]>;
  options: Observable<Option[]>;
  form : Observable<Form>;
  showQuestion : string;

  constructor(
    private route: ActivatedRoute, 
    private dataService: DataService,
    private afs: AngularFirestore
  ) {
    this.formId = this.route.snapshot.paramMap.get('id');
    this.showQuestion = '';
  }

  ngOnInit(){
    this.getForm();
    this.getOptions();
    this.getQuestions();
    this.getActiveQuestion();
  }

  getForm(): void{
    this.form = this.dataService.getForm(this.formId);  
  }

  getOptions(): void{
    this.options = this.dataService.getOptions(this.formId);
  }

  getQuestions(): void{
    this.questions = this.dataService.getQuestions(this.formId);
  }

  addQuestion(f: NgForm){
    this.dataService.addQuestion(f.value);
  }

  addOption(f: NgForm){
    this.dataService.addOption(f.value);
  }

  private async getActiveQuestion(){
    const qs = await this.afs.collection('forms').doc<Form>(this.formId).collection('meta',ref => ref.where('name', '==', 'showQuestion')).valueChanges();
    qs.forEach(doc => {if(doc.length > 0) {
      this.showQuestion = doc[0].value;
    }});
  }

}
