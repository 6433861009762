import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {NgForm} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Form } from '../shared/form';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent {

  private formCollection: AngularFirestoreCollection<Form>;
  forms: Observable<Form[]>;

  router: Router;

  constructor(private afs: AngularFirestore, router : Router) {

    this.router = router;
    this.formCollection = afs.collection<Form>('forms');

    this.forms = this.formCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );

   }

  addForm(f: NgForm){
    const randomId = Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4);
    const saveObj = {
      'title' : f.value.title,
      'meta' : {}
    }

    this.formCollection.doc(randomId).set(saveObj);
    this.formCollection.doc(randomId).collection('meta').add({'name' : 'showQuestion', 'value' : ''});

    this.router.navigateByUrl('/admin/' + randomId);
  }


}
