import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Option } from '../shared/option';
import { Question } from '../shared/question';
import { Form } from '../shared/form';
import { Vote } from '../shared/vote';
import { AuthService } from 'src/app/core/auth.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit{ 
  formId: string;
  questions: Observable<Question[]>;
  options: Observable<Option[]>;
  form : Observable<Form>;
  showQuestion : string;

  voteCollection: AngularFirestoreCollection<Vote>;
  votes: any;

  constructor(
    private afs: AngularFirestore, 
    private route: ActivatedRoute, 
    private router : Router, 
    public auth: AuthService,
    private dataService: DataService, 
  ) {
    this.showQuestion = '';
    this.formId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(){
      this.getForm();
      this.getOptions();
      this.getQuestions();
      this.getVotes();
      this.getActiveQuestion();
  }

  getForm(): void{
    this.form = this.dataService.getForm(this.formId);  
  }

  getOptions(): void{
    this.options = this.dataService.getOptions(this.formId);
  }

  getQuestions(): void{
    this.questions = this.dataService.getQuestions(this.formId);
  }

  private async getVotes(){
    this.voteCollection = this.afs.collection('forms').doc<Form>(this.formId).collection<Vote>('votes');
    this.votes = new Map();

    this.voteCollection.valueChanges().subscribe(
      (votes) => {
        this.votes = new Map();
        votes.forEach(vote => {
          this.addVote(vote.questionId, vote.optionId);   
        })
      }
    );
  }

  addVote(questionId, optionId){
    const optionValue = (this.votes.has(optionId)) ? this.votes.get(optionId) + 1 : 1;
    this.votes.set(optionId, optionValue);

    const questionnValue = (this.votes.has(questionId)) ? this.votes.get(questionId) + 1 : 1;
    this.votes.set(questionId, questionnValue); 

  }

  getVote(id){
    return this.votes.get(id);
  }

  private async getActiveQuestion(){
    const qs = await this.afs.collection('forms').doc<Form>(this.formId).collection('meta',ref => ref.where('name', '==', 'showQuestion')).valueChanges();
    qs.forEach(doc => {if(doc.length > 0) {
      this.showQuestion = doc[0].value;
    }});
  }

}

