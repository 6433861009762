import { Pipe, PipeTransform } from '@angular/core';
import { Vote } from './vote';

@Pipe({
    name: 'votefilter',
    pure: false
})

export class VoteFilterPipe implements PipeTransform {
    transform(items: Vote[], filter: Vote): Vote[] {
        if (!items || !filter) {
          return items;
        }

        // filter items array, items which match and return true will be kept, false will be filtered out
        return items.filter((item: Vote) => this.applyFilter(item, filter));
    }

    applyFilter(book: Vote, filter: Vote): boolean {
        for (let field in filter) {
          if (filter[field]) {
            if (typeof filter[field] === 'string') {
              if (book[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
                return false;
              }
            } else if (typeof filter[field] === 'number') {
              if (book[field] !== filter[field]) {
                return false;
              }
            }
          }
        }
        return true;
      }
}