import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Option } from '../shared/option';
import { Question } from '../shared/question';
import { Form } from '../shared/form';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private formDocument: AngularFirestoreDocument;
  private questionCollection: AngularFirestoreCollection<Question>;
  private optionCollection: AngularFirestoreCollection<Option>;
  
  constructor(private afs: AngularFirestore) { 
    
  }

  //Form =======
  getForms(): Observable<Form[]>{
    const forms = this.afs.collection('forms');
    return forms.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getForm(formId): Observable<Form>{
    this.formDocument = this.afs.collection('forms').doc<Form>(formId);
    return this.formDocument.valueChanges();
  }

  public async showHideQuestion(questionId, show){
    const value = (show) ? questionId : false;
    const qs = await this.formDocument.collection('meta',ref => ref.where('name', '==', 'showQuestion')).ref.get();
    qs.forEach(doc => this.formDocument.collection('meta').doc(doc.ref.id).update({'value' : value}));
  }

  //Questions =========
  getQuestions(formId): Observable<Question[]>{
    this.questionCollection = this.afs.collection('forms').doc<Form>(formId).collection<Question>('questions');

    return this.questionCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
  addQuestion(object){
    this.questionCollection.add(object);
  }

  updateQuestion(questionId, updObj){
    this.questionCollection.doc(questionId).update(updObj);
  }

  deleteQuestion(questionId){
    this.questionCollection.doc(questionId).delete();
    this.deleteOptions(questionId);
  }

  private async deleteOptions(questionId: string){
    const qs = await this.afs.collection('options', ref => ref.where('questionId', '==', questionId)).ref.get();
    qs.forEach(doc => this.optionCollection.doc(doc.ref.id).delete());
  }

  // Options ========
  getOptions(formId): Observable<Option[]>{
    this.optionCollection = this.afs.collection('forms').doc<Form>(formId).collection<Option>('options');

    return this.optionCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  addOption(object){
    this.optionCollection.add(object);
  }

  deleteOption(optionId){
    this.optionCollection.doc(optionId).delete();
  }
}
