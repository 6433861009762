import { Routes, RouterModule } from '@angular/router';

import { CreateComponent } from './create/create.component';
import { AdminComponent } from './admin/admin.component';
import { VoteComponent } from './vote/vote.component';
import { ResultComponent } from './result/result.component';

import { AuthGuard } from './core/auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';

const appRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: VoteComponent
    },
    {
        path: 'login',
        component: UserProfileComponent
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        component: CreateComponent
    },
    {
        path: 'admin/:id',
        canActivate: [AuthGuard],
        component: AdminComponent
    },
    {
        path: 'vote',
        canActivate: [AuthGuard],
        component: VoteComponent
    },
    {
        path: 'vote/:id',
        canActivate: [AuthGuard],
        component: VoteComponent
    },
    {
        path: 'result/:id',
        component: ResultComponent
    } 
];

export const AppRoutes = RouterModule.forRoot(appRoutes);