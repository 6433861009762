import { Pipe, PipeTransform } from '@angular/core';
import { Option } from './option';

@Pipe({
    name: 'optionfilter',
    pure: false
})

export class OptionFilterPipe implements PipeTransform {
    transform(items: Option[], filter: Option): Option[] {
        if (!items || !filter) {
          return items;
        }
        // filter items array, items which match and return true will be kept, false will be filtered out
        return items.filter((item: Option) => this.applyFilter(item, filter));
    }

    applyFilter(book: Option, filter: Option): boolean {
        for (let field in filter) {
          if (filter[field]) {
            if (typeof filter[field] === 'string') {
              if (book[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
                return false;
              }
            } else if (typeof filter[field] === 'number') {
              if (book[field] !== filter[field]) {
                return false;
              }
            }
          }
        }
        return true;
      }
}