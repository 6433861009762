import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Option } from '../shared/option';
import { Question } from '../shared/question';
import { Form } from '../shared/form';
import { Vote } from '../shared/vote';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/core/auth.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.css']
})

export class VoteComponent implements OnInit{  
  formId: string;
  questions: Observable<Question[]>;
  options: Observable<Option[]>;
  form : Observable<Form>;
  forms : Observable<Form[]>;

  showQuestion : string;

  private voteCollection: AngularFirestoreCollection<Vote>;
  votes: Observable<Vote[]>;

  fixedVotes;
  sessionVotes: Array<any>;

  constructor(
    private afs: AngularFirestore, 
    private route: ActivatedRoute, 
    private router : Router, 
    public auth: AuthService,
    private dataService: DataService, 
  ) {
    this.formId = this.route.snapshot.paramMap.get('id');
    this.showQuestion = '';
    this.sessionVotes = [];
  }

  ngOnInit(){
    if(this.formId){
      this.getForm();
      this.getOptions();
      this.getQuestions();
      this.getVotes();

      this.getActiveQuestion();

      this.controlForm();
    }
    
  }

  getForm(){
    this.form = this.dataService.getForm(this.formId); 
  }

  private async getActiveQuestion(){
    const qs = await this.afs.collection('forms').doc<Form>(this.formId).collection('meta',ref => ref.where('name', '==', 'showQuestion')).valueChanges();
    qs.forEach(doc => {if(doc.length > 0) {
      this.showQuestion = doc[0].value;
    }});
  }

  controlForm():void{
    this.dataService.getForm(this.formId).forEach(doc => {
      if(doc == undefined){
        this.router.navigateByUrl('/vote');
      }
    })
  }

  getOptions(): void{
    this.options = this.dataService.getOptions(this.formId);
  }

  getQuestions(): void{
    this.questions = this.dataService.getQuestions(this.formId);
  }

  private async getVotes(){
    this.fixedVotes = new Map();
    const qs = await this.afs.collection('forms').doc<Form>(this.formId).collection('votes',ref => ref.where('userId', '==', this.auth.userUid)).valueChanges();
    qs.forEach(doc => {if(doc.length > 0) {this.fixedVotes.set(doc[0].questionId, doc[0].optionId)}});
  }

  checkVote(questionId){
    if(this.fixedVotes.get(questionId)){
      return this.fixedVotes.get(questionId);
    }else{
      return false;
    }
  }
  
  vote(optionId, questionId){
    const voteObj = {
      userId : this.auth.userUid,
      optionId : optionId,
      questionId : questionId
    }
    
    if(this.sessionVotes.indexOf(questionId) < 0){
      this.sessionVotes.push(questionId);
      this.afs.collection('forms').doc<Form>(this.formId).collection<Vote>('votes').add(voteObj);
    }
      
  }

  openForm(f: NgForm){
    this.router.navigateByUrl('/vote/' + f.value.id);
  }
}
